import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="header">Jana Hutchison</header>
      <div className="subHeader">
        Actor &ndash; Improviser &ndash; Pole and Lyra Instructor
      </div>
      <div className="separator" />
      <div className="subHeader">
        Owner &ndash;&nbsp;
        <a className="link" href="https://www.ravenstudiosseattle.com">Raven Studios</a>
      </div>
      <div className="subHeader">
        Contact &ndash;&nbsp;
        <a className="link" href="mailto:jana@janahutchison.com">jana@janahutchison.com</a>
      </div>
    </div>
  );
}

export default App;
